const people = [
  {
    name: '超市冷冻冷藏业务',
    href: '/service/31',
    imageUrl: require('../images/area/宽直角端头立柜.png'),
        description:'作为海尔开利、西科、艾默生等的众多品牌经销商，可在超市陈列柜、压缩机组及变频压缩冷凝机组、换热器、后补式冷库等方面，为商超客户提供冷冻冷藏的整体解决方案'
},
{
    name: '冷库业务',
    href: '/service/32',
    imageUrl:
    require('../images/sample1/sample3.jpg'),
        description:'根据客户需求，制定合理设计规划，将自动化技术、远程监控技术和节能技术融入冷库建设中，通过技术集成和设计优化，为用户提供可靠性高、能耗低和智能化程度高的冷冻冷藏环境。'
},
{
    name: '空调业务',
    href: '/service/33',
    imageUrl:
    require('../images/area/aircon.png'),
        description:'供热、制冷、通风与空调工程设计、工程安装、设备销售以及运行维护。'
},
{
    name: '设备维护、保养与改造',
    href: '/service/34',
    imageUrl:
    require('../images/service4/sample13.jpg'),
        description:'为客户提供包括定期巡检及维修保养在内的设备运维服务，保障用户设备安全运行，主要包括:①质保期内、外的巡检;②线上+线下+远程监控+摄像头双周报机制。'
},
  
]

  
  export default function Feature() {
    return (
      <div className="relative isolate bg-white pb-32 pt-24 sm:pt-32">
        {/* <div
          className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
          aria-hidden="true"
        >
          <div
            className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div> */}
        {/* <div
          className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
          aria-hidden="true"
        >
          <div
            className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div> */}
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-xl text-center">
            {/* <h2 className="text-lg font-semibold leading-8 tracking-tight text-biboblue-600">碧波制冷</h2> */}
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            节能、简单、可靠
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-600 text-left">
              <ul>
                <li>✓ 丰富的<b>EPC</b>经验，从规划、设计、采购、施工、调试、维保进行全套完善服务。</li>
                <li>✓ 全系列产品，温区覆盖 <b>-50度~+28度</b> </li>
                <li>✓ <b>节能</b>技术，变频，波谷用电，<b>智能</b>调节</li>
                <li>✓ 对于温度控制，食品安全工艺流程，净化车间提供<b>专业设计指导</b></li>
                <li>✓ 对于房间过于分散，冷负荷变化剧烈，高温食品快速冷却等难题提供<b>成熟解决方案</b></li>
                <li>✓ 温度稳定性，系统节能型，设备安全型<b>均高于</b>行业标准</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-12">
            <ul
                className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4"
            >
                {people.map((person) => (
                    <li key={person.name}>
                      <a href={person.href}><img className="aspect-[3/2] w-full rounded-2xl object-cover" src={person.imageUrl} alt="" /></a>
                      <a href={person.href}><h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-gray-900">{person.name}</h3></a>
                      <a href={person.href}><p className="text-lg leading-7 text-gray-600 indent-9">{person.description}</p></a>
                    </li>
                ))}
            </ul>
        </div>

      </div>
    )
  }
  