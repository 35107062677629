import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Gallery from '../components/gallery';

const post = {
    title: '公司资质',
    imgStartNum: 1,
    imgEndNum: 6,
    imgFolder: 'cert'
  }
const hornor = {
    title: '公司荣誉',
    imgStartNum: 1,
    imgEndNum: 18,
    imgFolder: 'hornor'
  }
export default function About() {
    return (
        <div className="bg-white px-6 py-32 lg:px-8 lg:w-full">
            <div className="mx-auto lg:w-4/5 text-lg leading-7 text-gray-700">
                {/* <p className="text-3xl font-semibold leading-7 text-biboblue text-center">碧波制冷设备有限公司</p> */}
                <h1 className="mt-10 text-3xl font-bold tracking-tight text-biboblue sm:text-4xl text-center  lg:text-5xl">碧波制冷设备有限公司</h1>
                <h1 className="mt-5 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">公司简介</h1>
                <div className="mt-10 ">
                    <p className='indent-10'>
                        深圳市碧波制冷设备有限公司（以下简称碧波）成立于2003年，是一家集经营销售、设计安装、技术服务、7*24小时售后维保为一体的制冷系统集成服务商，主要从事商用冷柜、制冰机、冷库、机电设备以及空调工程设计安装的专业制冷工程公司。
                    </p>
                    <p className='indent-10'>
                        碧波具有建筑机电安装工程专业承包三级资质证书，安全生产许可证，特种设备工业压力管道安装（GC2）资质，中国设备维修安装企业能力等级证书（制冷空调C类II级、D类II级）。
                    </p>
                    <p className="mt-8 indent-10">
                        碧波重诚信，求质量，工程师团队均持技术“四证”及十年以上的实际操作经验。碧波能承接大、中、小型冷库、陈列柜、真空预冷装置、中央空调、制冰机、并联机组等工程业务，熟悉各种制冷设备的性能、规格、维修及安装方法。对各类冷库、中央空调和商超制冷设备的设计与安装尤为熟练，能为客户提供最优化的工程设计、施工方案，节能、绿色轻碳解决方案，充分满足客户的多样需求。
                    </p>
                    {/* <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900"></h2> */}
                    <h1 className="mt-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">金牌经销商、金牌安装商</h1>
                    <p className="mt-6 indent-10">
                        碧波成立20年来，我们承接了沃尔玛、家乐福、大润发、乐购、天虹等国内连锁超市冷柜冷库工程。碧波作为海尔开利的经销商，连续多年被青岛海尔开利冷冻设备有限公司授予于金牌经销商、金牌安装商。
                    </p>

                    <p className="mt-10 indent-10">
                        在各类物流配送中心冷库、医药冷库、防爆库、果蔬生鲜冷库、中央厨房冷库等领域，我们都大显身手，如江西吉安军粮库、惠东九华冷库、深圳清湖冷库、深圳致君制药厂冷库、深圳迈瑞生物冷库、深圳一致药业坪山冷库、深圳亚能生物冷库、深圳人才研修院冷库、深圳人才斗门水产鲜虾速冻生产线、富士康中央厨房冷库、内蒙古正蓝旗土豆原种库、惠州伊利冷库、苏州食品集团无锡冷库、百果园全国各配送中心冷库等中大型冷库工程，碧波服务的专业性及时效性赢得用户认可，成为多年来携手共进的战略合作伙伴。
                    </p>
                    <p className="mt-10 indent-10">
                        我们的服务宗旨是：同行也能做到的叫义务，同行做不到的才叫服务。我们期待为您提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。</p>
                </div>
                <Gallery posts={post}/>
               
                <Gallery posts={hornor}/>

                
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">合作伙伴</h2>
               
                <div className="mt-10 text-center">
                    <p>
                    排名不分先后
                    </p>
                </div>
                <div className='mt-6 hidden md:block'>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i=1;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+3}.png`)} alt={`Logo ${i+3}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+4}.png`)} alt={`Logo ${i+4}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+5}.png`)} alt={`Logo ${i+5}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i = 7;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+3}.png`)} alt={`Logo ${i+3}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+4}.png`)} alt={`Logo ${i+4}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+5}.png`)} alt={`Logo ${i+5}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i = 13;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+3}.png`)} alt={`Logo ${i+3}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+4}.png`)} alt={`Logo ${i+4}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+5}.png`)} alt={`Logo ${i+5}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                </div>

                <div className='mt-6 block md:hidden'>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i=1;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i=4;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i=7;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i=10;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i=13;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    <Carousel showStatus={false} showIndicators={false} showThumbs={false} showArrows={false} >
                    {
                        (() => {
                            const partner = [];
                            let i=16;
                            partner.push(
                                    <div className="flex items-center justify-center p-4">
                                        <img src={require(`../images/partner/partner${i}.png`)} alt={`Logo ${i}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+1}.png`)} alt={`Logo ${i+1}`} className="h-16 object-contain" />
                                        <img src={require(`../images/partner/partner${i+2}.png`)} alt={`Logo ${i+2}`} className="h-16 object-contain" />
                                    </div>
                            );
                            return partner;
                        })()
                    }
                    </Carousel>
                    
                </div>

            </div>

        </div>

    )
}
