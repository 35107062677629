import { QuestionMarkCircleIcon, PhoneIcon, EnvelopeIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
import CarouselComponent from '../components/carousel'
const posts = [
  {
    name: '超市冷冻冷藏业务',
    href: '/service/31',
    imageUrl: require('../images/area/宽直角端头立柜.png'),
    description: '作为海尔开利、西科、艾默生等的众多品牌经销商，可在超市陈列柜、压缩机组及变频压缩冷凝机组、换热器、后补式冷库等方面，为商超客户提供冷冻冷藏的整体解决方案'
  },
  {
    name: '冷库业务',
    imageUrl:
      require('../images/sample1/sample3.jpg'),
    description: '根据客户需求，制定合理设计规划，将自动化技术、远程监控技术和节能技术融入冷库建设中，通过技术集成和设计优化，为用户提供可靠性高、能耗低和智能化程度高的冷冻冷藏环境。'
  },
  {
    name: '空调业务',
    imageUrl:
      require('../images/area/aircon.png'),
    description: '供热、制冷、通风与空调工程设计、工程安装、设备销售以及运行维护。'
  },
  {
    name: '设备维护、保养与改造',
    imageUrl:
      require('../images/service4/sample13.jpg'),
    description: '为客户提供包括定期巡检及维修保养在内的设备运维服务，保障用户设备安全运行，主要包括:①质保期内、外的巡检;②线上+线下+远程监控+摄像头双周报机制。'
  },
  {
    name: '改造业务',
    imageUrl: require('../images/service4/sample13.jpg'),
    description: '我们的专业团队会对现有系统进行评估，并根据客户的需求和预算，通过引入最新的技术和创新的设计，提供量身定制的改造方案。'
  },
]
function getService(caseID){
  const posts = []
  if(caseID==="31"){
    for (let i = 0; i < 11; i++) {
      posts.push(
          {
              imageUrl: require(`../images/service1/sample${i+1}.jpg`),
          }
      );
    }
  }else if(caseID==="32"){
    for (let i = 0; i < 14; i++) {
      posts.push(
          {
              imageUrl: require(`../images/service2/sample${i+1}.jpg`),
          }
      );
    }
  }else if(caseID==="34"){
    for (let i = 0; i < 37; i++) {
      posts.push(
          {
              imageUrl: require(`../images/service4/sample${i+1}.jpg`),
          }
      );
    }
  }
  
  return posts;
}


export default function Service({ caseID }) {
  const service = getService(caseID);
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1  gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-10">

          {
            (() => {
              if (caseID === '34') return (
                <div className='lg:col-span-7'>
                  <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24">
                    <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                      <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">业务领域</h1>
                      <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 31].name}</h1>

                      <div className="mt-10 max-w-2xl">

                        <figure className="mt-10 border-l border-biboblue-600 pl-9">
                          <blockquote className="font-semibold text-gray-900">
                            <p className='indent-9'>
                              {posts[caseID - 31].description}
                            </p>
                          </blockquote>
                        </figure>
                      </div>
                      <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                          {service.map((post) => (
                            <article key={post.id} className="flex flex-col items-start justify-between">
                              <div className="relative w-full">
                                <img
                                  src={post.imageUrl}
                                  alt=""
                                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                              </div>
                            </article>
                          ))}
                        </div>
                    </div>
                  </div>

                </div>
              );
              else if (caseID === "31" ||caseID === "32") {
                return (
                  <div className='lg:col-span-7'>
                    <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24">
                      <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                        <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">业务领域</h1>
                        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 31].name}</h1>

                        <div className="mt-10 max-w-2xl">

                          <figure className="mt-10 border-l border-biboblue-600 pl-9">
                            <blockquote className="font-semibold text-gray-900">
                              <p className='indent-9'>
                                {posts[caseID - 31].description}
                              </p>
                            </blockquote>
                          </figure>
                        </div>
                        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                          {service.map((post) => (
                            <article key={post.id} className="flex flex-col items-start justify-between">
                              <div className="relative w-full">
                                <img
                                  src={post.imageUrl}
                                  alt=""
                                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                              </div>
                            </article>
                          ))}
                        </div>
                      </div>
                    </div>

                  </div>

                )
              }
              else
                return (
                  <div className='lg:col-span-7'>
                    <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24">
                      <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                        <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">业务领域</h1>
                        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 31].name}</h1>

                        <div className="mt-10 max-w-2xl">

                          <figure className="mt-10 border-l border-biboblue-600 pl-9">
                            <blockquote className="font-semibold text-gray-900">
                              <p className='indent-9'>
                                {posts[caseID - 31].description}
                              </p>
                            </blockquote>
                          </figure>
                          <CarouselComponent caseID={caseID} />
                        </div>
                      </div>
                    </div>

                  </div>

                )
            })()
          }

          <div className="lg:pt-4 ml-24 lg:col-span-3">
            <div className="lg:max-w-lg lg:ml-24">
              <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">联系我们</h1>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <PhoneIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    销售热线
                  </dt>
                  <dd><b>何小姐</b> 13590268805</dd>
                </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <QuestionMarkCircleIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    维保热线
                  </dt>
                  <dd><b>张小姐</b> </dd>
                    <dt>0755-86613772 / 15013628369</dt>
                </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <EnvelopeIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    电子邮箱
                  </dt>
                  <dd>bobo_refrig@163.com</dd>
                </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <DevicePhoneMobileIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    微信公众号
                  </dt>
                  <dd>
                    <img src={require('../images/wechat.jpg')} className="h-32 w-auto" alt='' />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
