import BlogImages from "../components/blog-images"
import Feature from "../components/feature"
import Hero from "../components/hero"
import Cases from "./cases"
import Stats from "../components/stats"

export default function Home() {
  return (
    <div>
        <Hero/>
        <Feature/>
        <Stats/>
        {/* <HeaderStats/> */}
        <Cases/>
        <BlogImages/>
    </div>
  )
}