const posts = [
    {
        title: '某水果全产业链配送中心',
        href: '/case/20',
        description:
            '某著名水果全产业链企业东莞配送中心冷库项目，冷库面积约20000平方，其中包含0度温区（预冷区、冷藏间、冷藏作业区等）、15度温区（低温收货间、存储区、初加工间、催熟库等）、10度温区（冷藏间、）-18度温区（速冻库、冷冻库、榴莲库），大大小小共计65个冷间。该项目采用节能设计，全自动智能控制，可手机远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。催熟库具有降温及加热功能，可实现库内温度根据催熟工艺需要在15-25℃之间自由调制。',
        imageUrl: require('../images/sample1/sample1.jpg')
    },
    {
        title: '江西吉安军粮储备库项目',
        href: '/case/23',
        description:
            '冷库建筑共3层，冷库层高7.5米，库间总面积约10000平方，其中包含-35℃速冻库约120平方、-18℃冷藏库3000平方以及0℃/-18℃变温库约7000平方。变温库可根据实际储藏需要切换冷间温度，在0℃与-18℃之间自由切换，实现储藏肉类或粮食的不同温度需求。该项目采用环保R507A制冷剂，制冷剂充注量较传统设计降低70%，系统能耗降低30%，全自动智能控制。深圳碧波将一如既往，为客户提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。',
        imageUrl: require('../images/sample4/sample4.jpg')
    },
    {
        title: '某移动互联网生鲜电商平台前置仓',
        href: '/case/21',
        description:
            '某著名移动互联网生鲜电商平台前置仓项目，冷库面积约300平方，其中包含冰鲜库，中温库，果蔬库及冷冻库。整个项目实现步入式、候补式冷库、加工间双温岛柜、双吸气机组、远程监测。所有冷库均采用后补式冷库的形式，实现仓储➕出样➕销售一体，达到①大包装（量贩销售，代替传统小包装）；②高坪效（上货即完成陈列，降低牌面陈列人工）；③代替传统冷柜销售模式全新购物体验。整体外观设计大气，其强劲的制冷效果、除雾玻璃、自动回弹定位门等细节能有效提高拣货效率，且其经由后补的补货方式，使库内食品不会出现长时间保存而变质现象。',
        imageUrl: require('../images/sample2/sample2.jpg')
    },
    {
        title: '某生物医药公司生产储存冷库项目',
        href: '/case/24',
        description:
            '包含5℃过渡库，5℃中温库及-18～-30℃低温库。该项目采用节能设计，全自动智能控制，采用热氟化霜的冲霜设计，较传统设计大幅降低日常使用能耗。系统采用PLC控制系统，可远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。',
        imageUrl: require('../images/sample5/sample2.jpg')
    },
    {
        title: '某著名连锁餐饮集团中央厨房项目',
        href: '/case/22',
        description:
            '包含冷藏库，冷冻库，预冷间，速冻库等。速冻库采用双级配搭机组满足客户-42℃的速冻制冷要求，高温机组采用1台比泽尔螺杆机组，搭配2台比泽尔螺杆机组，高温阶段使用高温机组，一台低温机组配搭；低温阶段一台高温机组，搭配两台低温机组，系统采用环保R507A制冷剂，全自动智能控制，整体能耗降低30%。该项目可手机远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。',
        imageUrl: require('../images/sample3/sample5.jpg')
    },
    
    
]

export default function BlogImages() {
    return (
        <div className="bg-slate-100 py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:max-w-4xl">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">样版工程</h2>
                    <p className="mt-2 text-lg leading-8 text-gray-600 text-center">
                        对各类冷库、中央空调和商超制冷设备的设计与安装尤为熟练，能为超市、水产、肉类、奶业、预制菜、果蔬保鲜、仓储物流、生物制药等行业提供制冷全套解决方案及工程设计、维修保养、节能工程技术服务、绿色轻碳解决方案等增值服务，充分满足客户的多样需求。
                    </p>
                    <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
                        {posts.map((post) => (
                            <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
                                <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                                    <img
                                        src={post.imageUrl}
                                        alt=""
                                        className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                                    />
                                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                                </div>
                                <div>

                                    <div className="group relative max-w-xl">
                                        <h3 className="mt-3 text-xl font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                                            <a href={post.href}>
                                                <span className="absolute inset-0" />
                                                {post.title}
                                            </a>
                                        </h3>
                                        <p className="mt-5 mb-5 text-base leading-6 text-gray-600 indent-8">{post.description}</p>
                                        <a href={post.href} className="text-base font-semibold leading-6 text-gray-900">
                                            <span aria-hidden="true">更多&rarr;</span>
                                        </a>
                                    </div>
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
