import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function CarouselComponent({caseID}) {
  return (
    <div className=' mt-8 '>
      <Carousel autoPlay showIndicators={true} infiniteLoop showThumbs={false} showArrows={true}>
        {
          (() => {
            if(caseID==='20' || caseID==='21'){
              const images = [];
              for (let i = 1; i <= 11; i++) {
                images.push(<img src={require(`../images/sample${caseID-19}/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 
            if(caseID==='22'){
              const images = [];
              for (let i = 1; i <= 18; i++) {
                images.push(<img src={require(`../images/sample${caseID-19}/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 
            if(caseID==='23'){
              const images = [];
              for (let i = 1; i <= 17; i++) {
                images.push(<img src={require(`../images/sample${caseID-19}/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 
            if(caseID==='24'){
              const images = [];
              for (let i = 1; i <= 4; i++) {
                images.push(<img src={require(`../images/sample${caseID-19}/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 

            if(caseID==='31'){
              const images = [];
              for (let i = 1; i <= 11; i++) {
                images.push(<img src={require(`../images/service1/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 
            if(caseID==='32'){
              const images = [];
              for (let i = 1; i <= 14; i++) {
                images.push(<img src={require(`../images/service2/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 
            if(caseID==='33'){
              const images = [];
              for (let i = 1; i <= 1; i++) {
                images.push(<img src={require(`../images/service3/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 
            if(caseID==='34'){
              const images = [];
              for (let i = 1; i <= 19; i++) {
                images.push(<img src={require(`../images/service4/sample${i}.jpg`)} className=' rounded-lg object-contain h-80' alt='' />)
              }
              return images;
            } 
          })()
        }
      </Carousel>
    </div>
  );
}
