
function initPost(posts){
  const gallery=[];
    for (let i = posts.imgStartNum; i <= posts.imgEndNum; i++) {
      if(posts.title==="公司荣誉"){
        gallery.push(
          {
              id: i,
              imageUrl: require(`../images/${posts.imgFolder}/honor${i}.jpg`),
              href: "/case/"+(i+1)
          }
      );
      }else{
        gallery.push(
          {
              id: i,
              imageUrl: require(`../images/${posts.imgFolder}/img${i}.jpg`),
              href: "/case/"+(i+1)
          }
      );
      }
      
    }
    return gallery;
}
function firstPost(posts){
  const gallery=[];
    for (let i = posts.imgStartNum; i <= 2; i++) {
      
        gallery.push(
          {
              id: i,
              imageUrl: require(`../images/${posts.imgFolder}/img${i}.jpg`),
              href: "/case/"+(i+1)
          }
      );
      
    }
    return gallery;
}
function secondPost(posts){
  const gallery=[];
    for (let i = 3; i <= posts.imgEndNum; i++) {
      
        gallery.push(
          {
              id: i,
              imageUrl: require(`../images/${posts.imgFolder}/img${i}.jpg`),
              href: "/case/"+(i+1)
          }
      );
      
    }
    return gallery;
}


  
export default function Gallery({posts}) {
    
    if(posts.title==="公司荣誉"){
      const gallery = initPost(posts);
      return (
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto  px-6 lg:px-8">
            <div className="mx-auto  text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{posts.title}</h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
              {/* 对各类冷库、中央空调和商超制冷设备的设计与安装尤为熟练，能为超市、水产、肉类、奶业、预制菜、果蔬保鲜、仓储物流、生物制药等行业提供制冷全套解决方案及工程设计、维修保养、节能工程技术服务、绿色轻碳解决方案等增值服务，充分满足客户的多样需求。 */}
              {posts.description}
              </p>
            </div>
            <div className="mx-auto mt-16 grid grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-4">
              {gallery.map((post) => (
                <article key={post.id} className="flex flex-col items-start justify-between">
                  <div className="relative w-full">
                    <img
                      src={post.imageUrl}
                      alt=""
                      
                      className="w-full rounded-2xl bg-gray-100 object-cover aspect-[16/9] sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    {/**/}
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10 " />
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      )
    }else{
      const gallery1 = firstPost(posts);
      const gallery2 = secondPost(posts);
      return (
        <div className="bg-white py-24 sm:py-32">
          <div className="mx-auto px-6 lg:px-8">
            <div className="mx-auto text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{posts.title}</h2>
              <p className="mt-2 text-lg leading-8 text-gray-600">
              {/* 对各类冷库、中央空调和商超制冷设备的设计与安装尤为熟练，能为超市、水产、肉类、奶业、预制菜、果蔬保鲜、仓储物流、生物制药等行业提供制冷全套解决方案及工程设计、维修保养、节能工程技术服务、绿色轻碳解决方案等增值服务，充分满足客户的多样需求。 */}
              {posts.description}
              </p>
            </div>
            <div className="mx-auto mt-16 grid grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
              {gallery1.map((post) => (
                <article key={post.id} className="flex flex-col items-start justify-between">
                  <div className="relative w-full flex items-center">
                    <img
                      src={post.imageUrl}
                      alt=""
                      
                      className="w-full rounded-2xl bg-gray-100 object-cover aspect-[16/9] sm:aspect-[2/1] lg:aspect-[3/2]"
                    />
                    {/*aspect-[16/9] sm:aspect-[2/1] lg:aspect-[3/2]*/}
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </article>
              ))}
            </div>
            <div className="mx-auto mt-16 grid grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-4">
              {gallery2.map((post) => (
                <article key={post.id} className="flex flex-col items-start justify-between">
                  <div className="relative w-full flex items-center">
                    <img
                      src={post.imageUrl}
                      alt=""
                      
                      className="w-full rounded-2xl bg-gray-100 "
                    />
                    {/*aspect-[16/9] sm:aspect-[2/1] lg:aspect-[3/2]*/}
                    <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                  </div>
                </article>
              ))}
            </div>
          </div>
        </div>
      )
    }
    
  }
  