const navigation = {
    pages: [
      { name: '首页', href: '/' },
      { name: '关于碧波', href: '/about' },
      { name: '业务领域', href: '/area' },
      { name: '工程案例', href: '/case' },
      { name: '联系我们', href: '/contact' }
    ],
    cases: [
      { name: '某水果全产业链配送中心', href: '/case/20' },
      { name: '江西吉安军粮储备库项目', href: '/case/23' },
      { name: '某移动互联网生鲜电商平台前置仓', href: '/case/21' },
      
      
      { name: '某生物医药公司生产储存冷库项目', href: '/case/24' },
      { name: '某著名连锁餐饮集团中央厨房项目', href: '/case/22' },
    ],
    contact: [
      { name: '0755-86613360', href: 'tel:0755-86613360' },
      { name: 'bobo_refrig@163.com', href: 'mailto:bobo_refrig@163.com' }
    ],
    legal: [
      { name: 'Claim', href: '#' },
      { name: 'Privacy', href: '#' },
      { name: 'Terms', href: '#' },
    ]
  }
  
  export default function Footer() {
    return (
      <footer className="bg-biboblue" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8">
              <img
                className="h-20 bg-white"
                src={ require('../images/logo.png') }
                alt="DreamTec"
              />
              <p className="text-3xl  text-white">
              
              </p>
              <p className="text-base text-white">
              让客户放心的制冷系统集成服务商
              </p>
            </div>
            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-semibold leading-6 text-white"> </h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.pages.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base leading-6 text-white hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <h3 className="text-lg font-semibold leading-6 text-white">样版工程</h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.cases.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base leading-6 text-white hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-lg font-semibold leading-6 text-white">联系我们</h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.contact.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base leading-6 text-white hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                    <li >
                        <button  className="text-base leading-6 text-white hover:text-white">
                        <img src={ require('../images/wechat.jpg')}  className="h-24 w-auto" alt='' />
                        </button>
                      </li>
                  </ul>
                </div>
                {/* <div className="mt-10 md:mt-0">
                  <h3 className="text-base font-semibold leading-6 text-white">Legal</h3>
                  <ul className="mt-6 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base leading-6 text-white hover:text-white">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
          <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 flex justify-between">
            <div className="flex items-center">
              <p className="text-xs leading-5 text-white">深圳市碧波制冷设备有限公司 &copy; 2023 版权所有</p>
            </div>
            <div className="flex items-center">
              <p className="text-xs leading-5 text-white text-right mr-4">Powered By <u><a href='https://dreamtec.ai'>DreamTec.ai</a></u></p>
              <a href='https://dreamtec.ai'><img src={require('../images/logo-dt.png')} className='h-8 w-auto' alt="DreamTec.ai Logo" /></a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
  