import { QuestionMarkCircleIcon, PhoneIcon, EnvelopeIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
import CarouselComponent from '../components/carousel'

const postName = [ "江西吉安军粮库","百果园凤岗配送中心冷库" ,"深圳清湖冷链物流冷库",
                  "前置仓", "迈瑞医药冷库", "亚能生物医药冷库", 
                  "广州波客派冷库", "甘棠明善中央厨房项目", "深圳人才研修院果蔬冷库",
                  "盒马鲜生", "天虹商场超市", "大润发"
]

const posts = [
  {
    title: '某水果全产业链配送中心',
    href: '/case/20',
    description:
      '某著名水果全产业链企业东莞配送中心冷库项目，冷库面积约20000平方，其中包含0度温区（预冷区、冷藏间、冷藏作业区等）、15度温区（低温收货间、存储区、初加工间、催熟库等）、10度温区（冷藏间、）-18度温区（速冻库、冷冻库、榴莲库），大大小小共计65个冷间。该项目采用节能设计，全自动智能控制，可手机远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。催熟库具有降温及加热功能，可实现库内温度根据催熟工艺需要在15-25℃之间自由调制。',
    intro: '某著名水果全产业链企业东莞配送中心冷库项目，冷库面积约20000平方，其中包含0度温区（预冷区、冷藏间、冷藏作业区等）、15度温区（低温收货间、存储区、初加工间、催熟库等）、10度温区（冷藏间、）-18度温区（速冻库、冷冻库、榴  莲库），大大小小共计65个冷间。',
    feature: ['该项目采用节能设计，全自动智能控制，可手机远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。催熟库具有降温及加热功能，可实现库内温度根据催熟工艺需要在15-25℃之间自由调制。',
  '【热气融霜】本项目使用变频机组，并充分利用了压缩机排气的显热和潜热，化霜效率大大提高。化霜时机组排气热量通过回气管进入蒸发器，热气在蒸发器中冷凝，冷凝液体旁通管路进入供液管供其它蒸发器制冷用。相较于传统的电化霜方式，热气融霜节能比率达到10%以上，且化霜时间短，化霜效果显著，化霜时设备内部温升小，可以最大限度的保证食品温度。'],
    imageUrl: require('../images/sample1/sample1.jpg')
  },
  
  {
    title: '某移动互联网生鲜电商平台前置仓',
    href: '/case/21',
    description:
      '某著名移动互联网生鲜电商平台前置仓项目，冷库面积约300平方，其中包含冰鲜库，中温库，果蔬库及冷冻库。整个项目实现步入式、候补式冷库、加工间双温岛柜、双吸气机组、远程监测。所有冷库均采用后补式冷库的形式，实现仓储➕出样➕销售一体，达到①大包装（量贩销售，代替传统小包装）；②高坪效（上货即完成陈列，降低牌面陈列人工）；③代替传统冷柜销售模式全新购物体验。整体外观设计大气，其强劲的制冷效果、除雾玻璃、自动回弹定位门等细节能有效提高拣货效率，且其经由后补的补货方式，使库内食品不会出现长时间保存而变质现象。',
    intro: '某著名移动互联网生鲜电商平台前置仓项目，冷库面积约300平方，其中包含冰鲜库，中温库，果蔬库及冷冻库。整个项目实现步入式➕候补式冷库➕加工间双温岛柜➕双吸气机组➕远程监测。',
    feature: ['所有冷库均采用后补式冷库的形式，实现仓储➕出样➕销售一体，达到①大包装（量贩销售，代替传统小包装）；②高坪效（上货即完成陈列，降低牌面陈列人工）；③代替传统冷柜销售模式全新购物体验。',
    '整体外观设计大气，其强劲的制冷效果、除雾玻璃、自动回弹定位门等细节能有效提高拣货效率，且其经由后补的补货方式，使库内食品不会出现长时间保存而变质现象。',
    '该项目采用节能设计，全自动智能控制，可手机远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。'],
    imageUrl: require('../images/sample2/sample2.jpg')
  },
  {
    title: '某著名连锁餐饮集团中央厨房项目',
    href: '/case/22',
    description:
      '包含冷藏库，冷冻库，预冷间，速冻库等。速冻库采用双级配搭机组满足客户-42℃的速冻制冷要求，高温机组采用1台比泽尔螺杆机组，搭配2台比泽尔螺杆机组，高温阶段使用高温机组，一台低温机组配搭；低温阶段一台高温机组，搭配两台低温机组，系统采用环保R507A制冷剂，全自动智能控制，整体能耗降低30%。该项目可手机远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。',
    intro: '某著名连锁餐饮集团中央厨房项目，包含冷藏库，冷冻库，预冷间，速冻库等。',
    feature: '速冻库采用双级配搭机组满足客户-42℃的速冻制冷要求，高温机组采用1台比泽尔螺杆机组，搭配2台比泽尔螺杆机组，高温阶段使用高温机组，一台低温机组配搭；低温阶段一台高温机组，搭配两台低温机组，系统采用环保R507A制冷剂，全自动智能控制，整体能耗降低30%。该项目可手机远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。',
    imageUrl: require('../images/sample3/sample5.jpg')
  },
  {
    title: '江西吉安军粮储备库项目',
    href: '/case/23',
    description:
      '冷库建筑共3层，冷库层高7.5米，库间总面积约10000平方，其中包含-35℃速冻库约120平方、-18℃冷藏库3000平方以及0℃/-18℃变温库约7000平方。变温库可根据实际储藏需要切换冷间温度，在0℃与-18℃之间自由切换，实现储藏肉类或粮食的不同温度需求。该项目采用环保R507A制冷剂，制冷剂充注量较传统设计降低70%，系统能耗降低30%，全自动智能控制。深圳碧波将一如既往，为客户提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。',
      intro: '江西吉安军粮储备库项目，冷库建筑共3层，冷库层高7.5米，库间总面积约10000平方，其中包含-35℃速冻库约120平方、-18℃冷藏库3000平方以及0℃/-18℃变温库约7000平方。',
    feature:[`变温库可根据实际储藏需要切换冷间温度，在0℃与-18℃之间自由切换，实现储藏肉类或粮食的不同温度需求。`,`制冷系统采用桶泵供液的供液方式，提高速冻效率，节能省电。`,`该项目采用环保R507A制冷剂，制冷剂充注量较传统设计降低70%，系统能耗降低30%，全自动智能控制`,`制冷系统全自动智能控制，使用变频机组及电子膨胀阀，根据使用情况机组自动调节电机电源频率，膨胀阀自动调节开启度，既提高了冷冻冷藏能力，同时也降低了库内食品的干耗及冷库运行电耗，起到良好的节约成本的效果。`],
    imageUrl: require('../images/sample4/sample4.jpg')
  },
  {
    title: '某生物医药公司生产储存冷库项目',
    href: '/case/24',
    description:
      '包含5℃过渡库，5℃中温库及-18～-30℃低温库。该项目采用节能设计，全自动智能控制，采用热氟化霜的冲霜设计，较传统设计大幅降低日常使用能耗。系统采用PLC控制系统，可远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。',
      intro: '某生物医药公司生产储存冷库项目，包含5℃过渡库，5℃中温库及-18～-30℃低温库。',
    feature: '该项目采用节能设计，全自动智能控制，采用热氟化霜的冲霜设计，较传统设计大幅降低日常使用能耗。系统采用PLC控制系统，可远程监测冷间温度及制冷系统运行情况及短信报警功能，当冷间温度出现异常时，维修人员可以远程及时初步诊断故障原因并针对性做准备或处理。冷间温度均衡度要求高，任何时段冷间温度不得超过设定值±2℃，制冷系统设备均为一用一备以备化霜期间或设备故障期间仍能保证冷间温度达到要求。项目完工投入使用前，甲方聘请第三方对冷间温度进行温度验证，顺利通过验证投入使用。',
    imageUrl: require('../images/sample5/sample2.jpg')
  },
 
  
  
  
]

function getImages(caseID){
  const posts = []
  if(caseID==="20"){
    for (let i = 0; i < 12; i++) {
      posts.push(
          {
              imageUrl: require(`../images/sample${caseID-19}/sample${i+1}.jpg`),
              caseID:20,
          imageID:i+1
          }
      );
    }
  }else if(caseID==="21"){
    for (let i = 0; i < 11; i++) {
      posts.push(
          {
            imageUrl: require(`../images/sample${caseID-19}/sample${i+1}.jpg`),
            caseID:21,
            imageID:i+1
          }
      );
    }
  }else if(caseID==="22"){
    for (let i = 0; i < 18; i++) {
      posts.push(
          {
            imageUrl: require(`../images/sample${caseID-19}/sample${i+1}.jpg`),
            caseID:22,
            imageID:i+1
          }
      );
    }
  }
  else if(caseID==="23"){
  for (let i = 0; i < 44; i++) {
    posts.push(
        {
          imageUrl: require(`../images/sample${caseID-19}/sample${i+1}.jpg`),
          caseID:23,
          imageID:i+1
        }
    );
  }
}
  
  return posts;
}
export default function Contact({ caseID }) {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32 sm:px-6 mx-auto">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid  grid-cols-1 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-10">

          {
            (() => {
              if (caseID < 20) {
                return (
                  <div className='lg:col-span-7'>
                    <p className="mt-2 mb-10 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{postName[caseID - 1]}</p>
                    <img
                      style={{width: '100%'}}
                      src={require(`../images/cases/case${caseID}.jpg`)}
                      alt=''/>
                  </div>
                )
              } else if(caseID>=24){
                return (
                  <div className='lg:col-span-7'>
                    <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24 sm:px-6">
                      <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                        <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">样版工程</h1>
                        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 20].title}</h1>
                        <img
                        style={{width: '100%'}}
                          src={require(`../images/sample5/sample2.jpg`)}
                          alt='' />


                        <div className="mt-10 ">
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目概述</strong>
                            <p className='indent-9'>
                              {posts[caseID - 20].intro}
                            </p>
                          </div>
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目特点</strong>
                            <p className='indent-9'>
                              {posts[caseID - 20].feature}
                            </p>
                          </div>

                          <figure className="mt-10 border-l border-biboblue-600 pl-9">
                            <blockquote className="font-semibold text-gray-900">
                              <p>
                              深圳碧波将一如既往，为客户提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。
                              </p>
                            </blockquote>
                          </figure>
                          <CarouselComponent caseID={caseID}/>
                        </div>
                      </div>
                    </div>

                  </div>

                )
              }else if(caseID==='21'){
                const detailImages = getImages(caseID);
                return (
                  <div className='lg:col-span-7'>
                    <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24 sm:px-6">
                      <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                        <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">样版工程</h1>
                        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 20].title}</h1>
                        <img
                        style={{width: '100%'}}
                          src={require(`../images/sample2/sample${caseID - 19}.jpg`)}
                          alt='' />


                        <div className="mt-10 ">
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目概述</strong>
                            <p className='indent-9'>
                              {posts[caseID - 20].intro}
                            </p>
                          </div>
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目特点</strong>
                            <p>
                              <ul>
                            {(()=>{
                              const lis = [];
                              for (let i = 0; i < posts[caseID - 20].feature.length; i++) {
                                lis.push(<li> - {posts[caseID - 20].feature[i]}</li>)
                              } 
                              return lis;
                            })()
                            }
                          </ul>
                            </p>
                          </div>

                          <figure className="mt-10 border-l border-biboblue-600 pl-9">
                            <blockquote className="font-semibold text-gray-900">
                              <p>
                              深圳碧波将一如既往，为客户提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。
                              </p>
                            </blockquote>
                          </figure>
                        </div>
                        <div className="mx-auto mt-16 grid  grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                          {detailImages.map((post) => (
                            
                            <article key={post.id} className="flex flex-col items-start justify-between">
                              <div className="relative w-full">
                                <img
                                  src={post.imageUrl}
                                  alt=""
                                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                              </div>
                            </article>
                            
                          ))}
                        </div>
                      </div>
                    </div>

                  </div>

                )
              }else if(caseID==='23'){
                const detailImages = getImages(caseID);
                return (
                  <div className='lg:col-span-7'>
                    <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24 sm:px-6">
                      <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                        <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">样版工程</h1>
                        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 20].title}</h1>
                        <img
                        style={{width: '100%'}}
                          src={require(`../images/sample4/sample4.jpg`)}
                          alt='' />


                        <div className="mt-10 ">
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目概述</strong>
                            <p className='indent-9'>
                              {posts[caseID - 20].intro}
                            </p>
                          </div>
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目特点</strong>
                            <p>
                              <ul>
                            {(()=>{
                              const lis = [];
                              for (let i = 0; i < posts[caseID - 20].feature.length; i++) {
                                lis.push(<li> - {posts[caseID - 20].feature[i]}</li>)
                              } 
                              return lis;
                            })()
                            }
                          </ul>
                            </p>
                          </div>

                          <figure className="mt-10 border-l border-biboblue-600 pl-9">
                            <blockquote className="font-semibold text-gray-900">
                              <p>
                              深圳碧波将一如既往，为客户提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。
                              </p>
                            </blockquote>
                          </figure>
                        </div>
                        <div className="mx-auto mt-16 grid  grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                          {detailImages.map((post) => (
                            
                            <article key={post.id} className="flex flex-col items-start justify-between">
                              <div className="relative w-full">
                                <img
                                  src={post.imageUrl}
                                  alt=""
                                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                              </div>
                            </article>
                            
                          ))}
                        </div>
                      </div>
                    </div>

                  </div>

                )
              }else if(caseID==='20'){
                const detailImages = getImages(caseID);
                return (
                  <div className='lg:col-span-7'>
                    <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24 sm:px-6">
                      <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                        <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">样版工程</h1>
                        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 20].title}</h1>
                        <img
                        style={{width: '100%'}}
                          src={require(`../images/sample1/sample${caseID - 19}.jpg`)}
                          alt='' />


                        <div className="mt-10 ">
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目概述</strong>
                            <p className='indent-9'>
                              {posts[caseID - 20].intro}
                            </p>
                          </div>
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目特点</strong>
                            <p>
                              <ul>
                            {(()=>{
                              const lis = [];
                              for (let i = 0; i < posts[caseID - 20].feature.length; i++) {
                                lis.push(<li> - {posts[caseID - 20].feature[i]}</li>)
                              } 
                              return lis;
                            })()
                            }
                          </ul>
                            </p>
                          </div>
                          <img className='mt-6'
                        style={{width: '100%'}}
                          src={require(`../images/sample1/feature.jpg`)}
                          alt='' />

                          <figure className="mt-10 border-l border-biboblue-600 pl-9">
                            <blockquote className="font-semibold text-gray-900">
                              <p>
                              深圳碧波将一如既往，为客户提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。
                              </p>
                            </blockquote>
                          </figure>
                        </div>
                        
                        <div className="mx-auto mt-16 grid  grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                          {detailImages.map((post) => (
                            
                            <article key={post.id} className="flex flex-col items-start justify-between">
                              <div className="relative w-full">
                                <img
                                  src={post.imageUrl}
                                  alt=""
                                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                              </div>
                            </article>
                            
                          ))}
                        </div>
                      </div>
                    </div>

                  </div>

                )
              }
              else{
                const detailImages = getImages(caseID);
                return (
                  <div className='lg:col-span-7'>
                    <div className="bg-white lg:pr-8 lg:pt-4 lg:ml-24 sm:px-6">
                      <div className="mx-auto max-w-3xl text-lg leading-7 text-gray-700">
                        <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">样版工程</h1>
                        <h1 className="mt-2 mb-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">{posts[caseID - 20].title}</h1>
                        <img
                        style={{width: '100%'}}
                          src={require(`../images/sample3/sample5.jpg`)}
                          alt='' />


                        <div className="mt-10 ">
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目概述</strong>
                            <p className='indent-9'>
                              {posts[caseID - 20].intro}
                            </p>
                          </div>
                          <div className='mt-6'>
                            <strong className="font-semibold text-gray-900">项目特点</strong>
                            <p className='indent-9'>
                              {posts[caseID - 20].feature}
                            </p>
                          </div>

                          <figure className="mt-10 border-l border-biboblue-600 pl-9">
                            <blockquote className="font-semibold text-gray-900">
                              <p>
                              深圳碧波将一如既往，为客户提供专业及优质节能的冷冻冷藏成套工程和技术保障服务。
                              </p>
                            </blockquote>
                          </figure>
                        </div>
                        <div className="mx-auto mt-16 grid  grid-cols-1 gap-x-4 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                          {detailImages.map((post) => (
                            <article key={post.id} className="flex flex-col items-start justify-between">
                              <div className="relative w-full">
                                <img
                                  src={post.imageUrl}
                                  alt=""
                                  className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                                />
                                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                              </div>
                            </article>
                          ))}
                        </div>
                      </div>
                    </div>

                  </div>

                )
              }
            })()
          }

          <div className="lg:pt-4 ml-24 lg:col-span-3">
            <div className="lg:max-w-lg  lg:ml-24">
              <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">联系我们</h1>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <PhoneIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    销售热线
                  </dt>
                  <dd><b>何小姐</b> 13590268805</dd>
                  {/* <dd><b>林先生</b> 13424215730</dd> */}
                </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <QuestionMarkCircleIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    维保热线
                  </dt>
                  <dd><b>张小姐</b> </dd>
                    <dt>0755-86613772 / 15013628369</dt>
                </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <EnvelopeIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    电子邮箱
                  </dt>
                  <dd>bobo_refrig@163.com</dd>
                </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                  <dt className="inline font-semibold text-gray-900">
                    <DevicePhoneMobileIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue" aria-hidden="true" />
                    微信公众号
                  </dt>
                  <dd>
                    <img src={require('../images/wechat.jpg')} className="h-32 w-auto" alt='' />
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
