const posts = [
    {
        name: '超市冷冻冷藏业务',
        href: '/service/31',
        imageUrl: require('../images/area/宽直角端头立柜.png'),
            description:'作为海尔开利、西科、艾默生等的众多品牌经销商，可在超市陈列柜、压缩机组及变频压缩冷凝机组、换热器、后补式冷库等方面，为商超客户提供冷冻冷藏的整体解决方案。'
    },
    {
        name: '冷库业务',
        href: '/service/32',
        imageUrl:
        require('../images/sample1/sample3.jpg'),
            description:'根据客户需求，制定合理设计规划，将自动化技术、远程监控技术和节能技术融入冷库建设中，通过技术集成和设计优化，为用户提供可靠性高、能耗低和智能化程度高的冷冻冷藏环境。'
    },
    {
        name: '空调业务',
        href: '/service/33',
        imageUrl:
        require('../images/area/aircon.png'),
            description:'供热、制冷、通风与空调工程设计、工程安装、设备销售以及运行维护。'
    },
    {
        name: '设备维护、保养与改造',
        href: '/service/34',
        imageUrl:
        require('../images/service4/sample13.jpg'),
            description:'为客户提供包括定期巡检及维修保养在内的设备运维服务，保障用户设备安全运行，主要包括:①质保期内、外的巡检;②线上+线下+远程监控+摄像头双周报机制。'
    },
  ]
  
  export default function Area() {
    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">业务领域</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
                碧波制冷公司提供多种制冷设备业务，包括<b>超市冷冻冷藏业务</b>、<b>冷库业务和空调业务</b>，<b>以及设备的维护保养服务</b>。
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl auto-rows-fr grid-cols-1 gap-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            {posts.map((post) => (
              <article
                key={post.id}
                className="relative isolate flex flex-col justify-end overflow-hidden rounded-2xl bg-gray-900 px-8 pb-8 pt-80 sm:pt-48 lg:pt-80"
              >
                <img src={post.imageUrl} alt="" className="absolute inset-0 -z-10 h-full w-full object-cover" />
                <div className="absolute inset-0 -z-10 bg-gradient-to-t from-gray-900 via-gray-900/40" />
                <div className="absolute inset-0 -z-10 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
  
                <div className="flex flex-wrap items-center gap-y-1 text-base leading-6 text-white">
                  <div className="-ml-4 flex items-center gap-x-4">
                    <div className="flex gap-x-2.5 indent-8">
                      {post.description}
                    </div>
                  </div>
                </div>
                <h3 className="mt-3 text-lg font-semibold leading-6 text-white">
                  <a href={post.href}>
                    <span className="absolute inset-0" />
                    {post.name}
                  </a>
                </h3>
              </article>
            ))}
          </div>
        </div>
      </div>
    )
  }
  