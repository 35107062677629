import Footer from './components/footer.js';
import Header from './components/header.js';
import About from './pages/about.js';
import Home from './pages/home.js'
import Area from './pages/area.js';
import Contact from './pages/contact.js';
import Cases from './pages/cases.js';
import Case from './pages/case.js';
import BlogImages from './components/blog-images.js';
import Service from './pages/service.js';

function App() {

  let page;

  let path = window.location.pathname;
  
  if(path.startsWith('/case/')){
    let paths = path.split("/")
    page = <Case caseID={paths[2]} />;
  }else if(path.startsWith('/service/')){
    let paths = path.split("/")
    page = <Service caseID={paths[2]} />;
  }else{
    switch (window.location.pathname){
    
      case "/about":
        page = <About/>;
        break;
      case "/area":
        page = <Area/>;
        break;
      case "/cases":
        page = <Cases/>;
        break;
      case "/contact":
        page = <Contact/>;
        break;
      case "/case":
        page = <Case />;
        break;
      case "/sample":
        page = <BlogImages/>;
        break;
      default:
        page = <Home/>;
        break;
    }
  }

 

  return (
    <div className="App font-custom">

      <Header/>
      
      {page}

      <Footer />
    </div>
  );
}

export default App;
