const postName = [ "江西吉安军粮库","百果园凤岗配送中心冷库" ,"深圳清湖冷链物流冷库",
                  "前置仓", "迈瑞医药冷库", "亚能生物医药冷库", 
                  "广州波客派冷库", "甘棠明善中央厨房项目", "深圳人才研修院果蔬冷库",
                  "盒马鲜生", "天虹商场超市", "大润发"
]
//江西吉安军粮库,百果园凤岗配送中心冷库,深圳清湖冷链物流冷库  物流-果蔬-物流
//前置仓,迈瑞医药冷库,亚能生物医药冷库,                     餐饮-医药-医药
//广州波客派冷库,甘棠明善中央厨房项目,深圳人才研修院果蔬冷库  物流-餐饮-果蔬
//盒马鲜生,天虹商场超市,大润发                             商超-商超-商超

const postCate = [ "物流","果蔬", "物流", 
                  "餐饮", "医药", "医药", 
                  "物流", "餐饮","果蔬", 
                  "商超", "商超", "商超"]

function initPost(){
    const posts = []
    for(let j =0;j<postName.length;j++){
      let t = j+1;
      if(j===0){
        t = 23;
      }else if(j===1){
        t=20;
      }else if(j===3){
        t=21;
      }else if(j===7){
        t=22;
      }else if(j===5){
        t=24;
      }
      posts.push(
        {
            title: postName[j],
            category: postCate[j],
            imageUrl: require(`../images/cases/case${j+1}.jpg`),
            href: "/case/"+t
        }
    );
    }
    return posts;
}

const posts = initPost();
  
export default function Cases() {

    return (
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">工程案例</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600 text-left">
            <ul>
                <li>✓ 丰富的<b>EPC</b>经验，从规划、设计、采购、施工、调试、维保进行全套完善服务。</li>
                <li>✓ 全系列产品，温区覆盖 <b>-50度~+28度</b> </li>
                <li>✓ <b>节能</b>技术，变频，波谷用电，<b>智能</b>调节</li>
                <li>✓ 对于温度控制，食品安全工艺流程，净化车间提供<b>专业设计指导</b></li>
                <li>✓ 对于房间过于分散，冷负荷变化剧烈，高温食品快速冷却等难题提供<b>成熟解决方案</b></li>
                <li>✓ 温度稳定性，系统节能型，设备安全型<b>均高于</b>行业标准</li>
              </ul>
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <article key={post.id} className="flex flex-col items-start justify-between">
                <div className="relative w-full">
                  <img
                    src={post.imageUrl}
                    alt=""
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                    <button
                      className="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100"
                    >
                      {post.category}
                    </button>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-base leading-6 text-gray-600">{post.description}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
    )
  }
  