// import { XMarkIcon } from '@heroicons/react/20/solid'

export default function Banner() {
  return (
    <div className="flex items-center gap-x-6 bg-biboblue px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-base leading-6 text-white">
        <a href="/">
          <strong className="font-semibold">专注制冷20年</strong>
          <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
            <circle cx={1} cy={1} r={1} />
          </svg>
          做客户放心的制冷系统集成服务商&nbsp;<span aria-hidden="true">&rarr;咨询服务热线：0755-86613360</span>
        </a>
      </p>
      <div className="flex flex-1 justify-end">
        <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]">
          <span className="sr-only">Dismiss</span>
          {/* <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" /> */}
        </button>
      </div>
    </div>
  )
}