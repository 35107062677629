import { ArrowPathRoundedSquareIcon, BoltIcon, WrenchScrewdriverIcon, StarIcon } from '@heroicons/react/20/solid'

const features = [
    {
        name: '口碑信誉',
        description:
            '我们以口碑信誉闻名于业界。多年来，我们与众多客户建立了长期稳定的合作关系，并赢得了客户的信任和赞誉。我们的专业能力和优质服务得到了广泛认可，为客户创造了持久的价值。',

        icon: StarIcon,
    },
    
    {
        name: '公司实力',
        description:
            '我们作为一家实力雄厚的公司，拥有丰富的行业经验和专业团队。我们具备先进的设备和技术，能够为客户提供卓越的产品和服务。',

        icon: BoltIcon,
    },
    {
        name: '环保节能',
        description:
            '我们致力于提供环保节能的解决方案。我们的产品符合严格的环保标准，采用节能技术，帮助客户降低能耗并减少对环境的影响。',

        icon: ArrowPathRoundedSquareIcon,
    },
    {
        name: '售后维保',
        description:
            '我们注重客户满意度，提供全面的售后维护服务。无论是产品安装调试还是日常维护保养，我们都会及时响应客户需求，并提供高效可靠的售后支持。',

        icon: WrenchScrewdriverIcon,
    }
    
]

export default function Stats() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto text-center">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        服务优势
                    </h2>
                </div>
                <div className="mx-auto mt-8">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-4">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="flex items-center gap-x-3 text-lg font-semibold leading-7 text-gray-900">
                                    <feature.icon className="h-5 w-5 flex-none text-biboblue" aria-hidden="true" />
                                    {feature.name}
                                </dt>
                                <dd className="mt-4 flex flex-auto flex-col text-lg leading-7 text-gray-600">
                                    <p className="flex-auto indent-9">{feature.description}</p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
                <div className="mx-auto flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-10 lg:max-w-none lg:flex-row lg:items-end">
                    <div className="flex flex-col justify-center items-center gap-x-16 gap-y-8 rounded-2xl bg-red-500 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-center lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start">
                    <div class="relative">
                        <p class="flex-none text-3xl font-bold tracking-tight text-white">20</p >
                        <p class="absolute top-0 left-8 text-xl font-bold tracking-tight text-white">+</p >
                    </div>
                        <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                            <p className="text-lg font-semibold tracking-tight text-white">20余年</p>
                            <p className="mt-2 text-lg leading-7 text-white">
                                行业经验
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-x-16 gap-y-8 rounded-2xl bg-red-500 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-center lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start">
                    <div class="relative">
                        <p class="flex-none text-3xl font-bold tracking-tight text-white">200</p >
                        <p class="absolute top-0 left-12 text-xl font-bold tracking-tight text-white">+</p >
                    </div><div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                            <p className="text-lg font-semibold tracking-tight text-white">合作伙伴</p>
                            <p className="mt-2 text-lg leading-7 text-white">
                                200余家
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-x-16 gap-y-8 rounded-2xl bg-red-500 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-center lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start">
                    <div class="relative">
                        <p class="flex-none text-3xl font-bold tracking-tight text-white">4</p >
                        <p class="absolute top-0 left-4 text-xl font-bold tracking-tight text-white">+</p >
                    </div><div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                            <p className="text-lg font-semibold tracking-tight text-white">四大职能部门</p>
                            <p className="mt-2 text-lg leading-7 text-white">
                                销售、设计、工程、售后
                            </p>
                        </div>
                    </div>
                    <div class="flex flex-col justify-center items-center gap-x-16 gap-y-8 rounded-2xl bg-red-500 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-center lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start">
                    <div class="relative">
                        <p class="flex-none text-3xl font-bold tracking-tight text-white">3</p >
                        <p class="absolute top-0 left-4 text-xl font-bold tracking-tight text-white">+</p >
                    </div>
                    <div class="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                        <p class="text-lg font-semibold tracking-tight text-white">三小时内</p >
                        <p class="mt-2 text-lg leading-7 text-white">报修三小时内迅速到达</p >
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}
