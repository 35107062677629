import { QuestionMarkCircleIcon, PhoneIcon, EnvelopeIcon, DevicePhoneMobileIcon } from '@heroicons/react/20/solid'
export default function Contact() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
            {/* <h1 className="mb-4 text-3xl font-bold leading-7 text-biboblue sm:text-4xl">联系我们</h1> */}
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">深圳市碧波制冷设备有限公司</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                南山区桃源街道平山社区留仙大道4109号创客小镇13栋107-110
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <PhoneIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue-600" aria-hidden="true" />
                      销售热线
                    </dt>
                    <dd><b>何小姐</b> 13590268805</dd>
                    {/* <dd><b>林先生</b> 13424215730</dd> */}
                  </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <QuestionMarkCircleIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue-600" aria-hidden="true" />
                      维保热线
                    </dt>
                    <dd><b>张小姐</b> </dd>
                    <dt>0755-86613772 / 15013628369</dt>
                  </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <EnvelopeIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue-600" aria-hidden="true" />
                      电子邮箱
                    </dt>
                    <dd>bobo_refrig@163.com</dd>
                  </div>
              </dl>
              <dl className="mt-10 max-w-xl space-y-8 text-lg leading-7 text-gray-600 lg:max-w-none">
                <div className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <DevicePhoneMobileIcon className="absolute left-1 top-1 h-5 w-5 text-biboblue-600" aria-hidden="true" />
                      微信公众号
                    </dt>
                    <dd>
                      <img src={ require('../images/wechat.jpg')}  className="h-32 w-auto" alt='' />
                    </dd>
                  </div>
              </dl>
            </div>
          </div>
          <div className='lg:mt-24 lg:pt-4'>

          
          <a className='lg:mt-20' href='https://map.baidu.com/poi/%E6%B7%B1%E5%9C%B3%E5%B8%82%E7%A2%A7%E6%B3%A2%E5%88%B6%E5%86%B7%E8%AE%BE%E5%A4%87%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12687137.849395702,2565552.2030491913,18.37z?uid=3ee78599c2d54da3d5b74ed1&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl' >
          <img
            src= { require('../images/location.png') }
            alt="Product screenshot"
            className="w-full max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-full md:-ml-4 lg:-ml-0 "
            width={697}
            height={550}
            title="前往百度地图"
          />
          </a>
          </div>
        </div>
      </div>
    </div>
  )
}
